<template>
    <div class="welfare">
        <HeadBar titleName="福利中心"></HeadBar>
        <div class="content oepnX">
            <WelfareList></WelfareList>
        </div>
    </div>
</template>

<script>
import WelfareList from "@/views/welfare/WelfareList";
import HeadBar from "@/components/HeadBar";

export default {
    name:"WelfareCenter",
    components:{
        WelfareList,HeadBar
    }
}
</script>

<style lang="scss" scoped>
.welfare{
    
    .content{
        height: calc(100vh - 44px);
        padding: 0 12px;
    }
}
</style>