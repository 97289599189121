<template>
    <div class="welfareList oepnX">
        <PullRefresh v-if="!isLoading" :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
                     @onRefresh="onRefresh" :isNoData="isNoData" :error="error" :is-higeht-max="true"
                     :adv-list="advList" :hasAdvPagination="true" className="Welfare">
            <div>
                <div class="contentItem" v-for="(item,index) in welfareList" :key="index" @click="openDetails(item)">
                    <div class="specialTitle">
                        <span>{{item.title}}</span>
                    </div>
                    <ImgDecypt class="specialImg" :src="item.cover" />
                </div>
            </div>
        </PullRefresh>
        <Loading v-else />
    </div>
</template>

<script>
import {queryAnnouMsgList} from "@/api/welfare";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import ImgDecypt from "@/components/ImgDecypt";

export default {
    name:"WelfareList",
    components:{
        PullRefresh,
        Loading,
        ImgDecypt
    },
    data(){
        return {
            isLoading:true,
            loading: true,
            refreshing: false,
            finished: false,
            isNoData: false,
            error: false,
            advList: [],
            pageNumber: 1,
            pageSize: 10,
            welfareList:[]
        }
    },created() {
        this.getAnnouMsgList();
    },mounted() {

    },
    methods:{
        async getAnnouMsgList(type) {
            let req = {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize
            }
            let res = await this.$Api(queryAnnouMsgList, req);

            this.loading = false;
            this.refreshing = false;
            this.isLoading = false;

            if (res && res.code == 200) {
                if(!res.data){
                    this.isNoData = true;
                    return;
                }
                
                if (type == "refresh") {
                    this.welfareList = res.data.list;
                } else {
                    this.welfareList = this.welfareList.concat(res.data.list);
                }

                if (this.pageNumber === 1 && this.welfareList.length === 0) {
                    this.isNoData = true;
                }
                if (!res.data.hasNext || res.data.list.length < req.pageSize) {
                    this.finished = true;
                }
            }
        },onLoad(){
            this.loading = true;
            if (this.error) {
                this.error = false;
            } else {
                this.pageNumber++
            }
            this.getAnnouMsgList();
        },onRefresh() {
            this.pageNumber = 1;
            this.refreshing = true;
            this.finished = false;
            this.isNoData = false;
            this.getAnnouMsgList('refresh');
        },openDetails(item){
            this.$router.push({
                path:"/welfareDetails",
                query:{
                   // item:JSON.stringify(item)
                    id: item.id
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>

.welfareList{
    height: 100%;
        .contentItem{
            //background: rgb(27,22,76);
            border-radius: 6px;
            margin-top: 12px;

            // /deep/ .van-image{
            //     border-radius: 6px;
            // }

            // /deep/ .van-image__img{
            //     border-radius: 6px;
            // }
            
            .specialTitle{
                font-size: 15px;
                color: #000000;
                font-weight: bold;
            }
            
            .specialImg{
                height: 140px;
                margin-top: 6px;
            }
        }
}

</style>